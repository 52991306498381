import React, { Component } from "react";
import "./TopBar.css";

class TopBar extends Component {
  render() {
    return <div className="top-bar"></div>;
  }
}

export default TopBar;

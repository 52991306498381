import React, { Component } from "react";
import "./LowBar.css";

class LowBar extends Component {
  render() {
    return <div className="low-bar"></div>;
  }
}

export default LowBar;
